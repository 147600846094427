import React from "react"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Virtual Security Operations Center Support"
const pageTitle = "Virtual Security Operations Center Support"
const subTitle = "Business Process"
const para =
  "Largest US Aerospace and Defence manufacturer by revenue and market capitalization, and one of the largest providers of intelligence services"
const blockquote = `"Supporting Client interval VSOC (Virtual Sec. Operations Center) and their
customers"`

const cardItems = [
  {
    id: 1,
    heading: `The Need`,
    listItems: (
      <>
        <p>
          {/* <Icon.Square /> */}
          Client required support for their interval Virtual Security
          Operations Center (VSOC), as well as assistance for their customers,
          particularly the Government Publishing Office (GPO) and the State of
          Georgia. They needed SOC analysts, SMEs, and engineers for various
          cybersecurity technologies.
        </p>
        {/* <img  src={MapImg}/> */}
      </>
    ),
  },

  {
    id: 1,
    heading: `The Approach`,
    listItems: (
      <>
        <p>
          The approach involved deploying 20 resources. These resources are
          focused on fulfilling the requirements of the VSOC and providing
          direct support to customers. The team comprises of SOC analysts, as
          well as (SMEs) and engineers with expertise in technologies such as
          Splunk, ServiceNow, Sentinel, and Rapid 7. They also includes
          Information Security (InfoSec) and Network Security (NetSec)
          resources, including Firewall (WAFs), Incident Response, and Threat
          Hunting engineers.
        </p>
      </>
    ),
  },
  {
    id: 3,
    heading: `The Process:`,
    listItems: (
      <>
        <p>
          Over a span of 3-4 years, the team at Client sourced the necessary
          personnel to meet the cybersecurity needs. The team also delivered
          dedicated SMEs and engineers to the clients customers and internal
          teams, ensuring that the required cybersecurity technologies and
          expertise were readily available.
        </p>
      </>
    ),
  },
  {
    id: 4,
    heading: `Result`,
    listItems: (
      <>
        <p>
          The result of this effort is the successful support for the VSOC and
          strengthened the client's cybersecurity capabilities. The core
          technologies involved in this initiative included Splunk, ServiceNow,
          Sentinel, Rapid 7, Firewall (WAFs), Incident Response, Threat Hunting,
          and various cybersecurity tools and practices relevant to Security
          Operations Centers.
        </p>
      </>
    ),
  },
]

const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query VirtualSecurityOperationsCenterSupportQuery {
    casedetailsJson(
      slug: { eq: "virtual-security-operations-center-support" }
    ) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
